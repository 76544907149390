import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import { get, isEmpty } from 'lodash'

declare const RASA_ASSETS_CDN: string

export const getActivesSourcesCount = (user) => get(user, 'activeCommunity.billingInfo.usageStats.sourcesCount', 0)

export const arrayBufferToString = (buffer: ArrayBuffer): string => {
  try {
    let data = ''
    const bytes = new Uint8Array( buffer )
    let index = 0
    if (bytes[0] === 0xEF && bytes[1] === 0xBB && bytes[2] === 0xBF) {
      // BOM char throws of the code that evaluates CSV headers.  SO make sure to strip it - EFBBBF
      index = 3
    }
    for (; index < bytes.byteLength; index++) {
      data += String.fromCharCode( bytes[ index ] )
    }
    return data
  } catch (err) {
    return null
  }
}

export const getSourceType = (type: string) => {
  const result = Object.keys(sourceTypes).filter((st) => sourceTypes[st].includes(type.toLowerCase()))
  return result.length ? result[0] : 'RSS Feed'
}

const sourceTypes = {
  'Facebook': ['facebook'],
  'Twitter': ['twitter'],
  'RSS Feed': ['rss', 'rss-feed', 'rss feed'],
}

export const uploadFile = (
  community: string,
  fileFormat: string,
  fileContents: string,
  file: any,
  id: string = '',
) => {
  const formData = new FormData()
  formData.append(fileFormat, file)
  const queryString: string = [
    {
      key: 'format',
      value: fileFormat,
    },
    {
      key: 'contents',
      value: fileContents,
    },
    {
      key: 'id',
      value: id,
    },
  ].filter((param) => !isEmpty(param.value))
   .map((param) => `${param.key}=${param.value}`)
   .join('&')
  const url: string =
    `${AjaxWrapper.getServerUrl()}/${community}/file-upload?${queryString}`

  return AjaxWrapper.ajax(url, HttpMethod.POST, formData, null)
}

export const loadSampleArticles = (): Promise<string[]> => {
  return AjaxWrapper.ajax(`${RASA_ASSETS_CDN}/sample-content.json`, HttpMethod.GET, null)
                    .catch(() => [])
}

export const isUrlsSame = (url1: string, url2: string): boolean => {
  // search for 'www', 'http://www', 'https://www' and trailing slash '/' in string
  const regex = /^(https?:\/\/)?(www\.)?|\/$/gi
  return url1.replace(regex, '') === url2.replace(regex, '')
}