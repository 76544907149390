import {Routes} from 'universal-router'
import {routes as twoFactorAuth} from './pages/2fa-auth/routes'
import {routes as admin} from './pages/admin/routes'
import {routes as analytics} from './pages/analytics/routes'
import {routes as contentPool} from './pages/contentPool/routes'
import {routes as csDashboard} from './pages/cs-dashboard/routes'
import {routes as emailLayouts} from './pages/emailLayouts/routes'
import {routes as templateLibrary} from './pages/templateLibrary/routes'
import {routes as error} from './pages/error/routes'
import {routes as forgotPassword} from './pages/forgot-password/routes'
import {routes as handledError} from './pages/handled-error/routes'
import {routes as home} from './pages/home/routes'
import {routes as integrationsCallback} from './pages/integrations-callback/routes'
import {routes as login} from './pages/login/routes'
import {routes as logout} from './pages/logout/routes'
import {routes as preview} from './pages/preview/routes'
import {routes as resetPassword} from './pages/reset-password/routes'
import {routes as schedule} from './pages/schedule/routes'
import {routes as settings} from './pages/settings/routes'
import {routes as signup} from './pages/signup/routes'
import {routes as subscribers} from './pages/subscribers/routes'
import {routes as verifyEmail} from './pages/verifyEmail/routes'
import {routes as widgets} from './pages/widgets/routes'

export const routes: Routes = [
  ...admin,
  ...analytics,
  ...contentPool,
  ...csDashboard,
  ...emailLayouts,
  ...templateLibrary,
  ...error,
  ...forgotPassword,
  ...handledError,
  ...home,
  ...integrationsCallback,
  ...login,
  ...logout,
  ...preview,
  ...resetPassword,
  ...schedule,
  ...settings,
  ...signup,
  ...subscribers,
  ...twoFactorAuth,
  ...verifyEmail,
  ...widgets,
]
