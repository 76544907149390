import classnames from 'classnames'
import { isEmpty, isNil } from 'lodash'
import * as React from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { ModalSize } from './constants'
import './style.css'
import * as Types from './types'

export enum ModalActionType {
  BUTTON,
  LINK,
}

export interface ModalComponentProps extends Types.ModalActionsProps {
  closeButtonText?: string
  context?: any
  data: Types.ModalStateProps
  goTo?: any
  modalType?: any
  saveText?: string
  secondAction?: ModalActionType
  size?: ModalSize
  title?: string
}

export interface ModalComponentState {
  isSaving?: boolean
}

export class GenericModalComponent<T extends ModalComponentProps, S extends ModalComponentState>
  extends React.Component<T, S> {
  protected key: string
  private title: string
  constructor(props: T, key: string, title: string = '') {
    super(props)
    this.key = key
    this.title = title
    this.state = {
      isSaving: false,
    } as S
  }

  public render() {
    const {data, size} = this.props
    return <Modal
              className={`${this.key} rasa-modal ${size === ModalSize.Large ? 'modal-xl' : 'modal-lg'}`}
              isOpen={data[this.key].isOpen}>
              {this.showHeader(data[this.key].data) &&
              <ModalHeader toggle={() => this.xButtonClick(data[this.key].data)}>
                <div dangerouslySetInnerHTML={{__html: this.getTitleText(data[this.key].data)}}></div>
              </ModalHeader>
              }
              <ModalBody >
                {this.renderChildren(this.getData())}
              </ModalBody>
              <ModalFooter>
                {this.getFooterContent(this.getData())}
              { this.props.saveModal
                ?
                  <div className="footer-buttons">
                    <Button className={classnames('primary',
                          {'with-margin': !isNil(this.props.secondAction),
                          'disabled': this.saveDisabled(data[this.key].data)})}
                            onClick={() => this.save(data[this.key].data)}
                            disabled={this.state.isSaving || this.saveDisabled(data[this.key].data)}>
                      {this.getSaveText(data[this.key].data)}
                    </Button>
                  { this.props.secondAction === ModalActionType.LINK
                    ?
                      <span className="secondary clickable-item">
                        <a onClick={() => this.close(data[this.key].data)}>
                          {this.getCloseText(data[this.key].data)}
                        </a>
                      </span>
                    :
                      this.props.secondAction === ModalActionType.BUTTON
                      ?
                        <Button
                          disabled={
                            this.state.isSaving ||
                            this.secondActionDisabled(data[this.key].data)
                          }
                          className={classnames('primary',
                            {'disabled': this.secondActionDisabled(data[this.key].data)})}
                          onClick={() => this.close(data[this.key].data)}>
                          {this.getCloseText(data[this.key].data)}
                        </Button>
                      : null
                  }
                  </div>
                :
                  <div>
                    <Button
                      className="primary"
                      onClick={() => this.close(data[this.key].data)}>
                      {this.getCloseText(data[this.key].data)}
                    </Button>
                  </div>
                }
              </ModalFooter>
            </Modal>
  }

  protected showHeader(data: any) {
    return true
  }

  protected renderChildren(data: any) {
    // Override to insert children into the modal
    return null
  }

  protected onModalClose(data: any) {
    this.props.closeModal(this.key)
  }

  protected close(data: any) {
    this.onModalClose(data)
  }

  protected xButtonClick(data: any) {
    this.onModalClose(data)
  }

  protected getFooterContent(data: any) {
    // Override to insert children into the footer
    return null
  }

  protected save(data: any) {
    this.setState({isSaving: true})
    this.doSave(data)
        .then(() => {
          this.setState({ isSaving: false })
          this.props.saveModal(this.key, this.props.data)
        })
        .catch(() => this.setState({ isSaving: false }))
  }

  protected doSave(data: any): Promise<any> {
    return Promise.resolve({})
  }

  protected getSaveText(data: any): string {
    const {saveText} = this.props
    if (!isEmpty(data)) {
      return data.type === 'yesNo' ? 'YES' :  (saveText ? saveText : 'SAVE')
    }
    return  (saveText ? saveText : 'SAVE')
  }

  protected getCloseText(data: any): string {
    const {closeButtonText, saveModal, secondAction} = this.props
    if (saveModal && secondAction && !isEmpty(data)) {
      return data.type === 'yesNo' ? 'NO' : 'CLOSE'
    } else {
      return closeButtonText || 'CLOSE'
    }
  }

  protected getTitleText(data: any): string {
    return this.props.title || this.title
  }

  protected saveDisabled(data: any): boolean {
    return false
  }

  protected secondActionDisabled(data: any): boolean {
    return false
  }

  protected getData(): any {
    return this.props.data[this.key].data
  }
}

export class ModalComponent extends GenericModalComponent<ModalComponentProps, ModalComponentState>  {}
