import { DEFAULT_ERROR_MESSAGE } from 'components/flash'
import { SUPPORT_EMAIL } from '../../constants'

export enum ErrorCode {
  AccountCancelled = 'AccountCancelled',
  ExistingAccount = 'ExistingAccount',
  GeneralException = 'GeneralException',
  InvalidInvitation = 'InvalidInvitation',
  InvalidInvitationEmail = 'InvalidInvitationEmail',
  InvalidInputParameter = 'InvalidInputParameter',
  InputParameterRequired = 'InputParameterRequired',
  InvalidRecaptcha = 'InvalidRecaptcha',
  NoRecordFound = 'NoRecordFound',
  TimeoutError = 'Timeout',
  UserIdentityNotFound = 'UserIdentityNotFound',
}

export interface Error {
  code: ErrorCode,
  message: string,
}

const allErrors: Error[] = [
  {
    code: ErrorCode.AccountCancelled,
    message: `Sorry, your account is cancelled. Please contact us at ${SUPPORT_EMAIL} for assistance.`,
  },
  {
    code: ErrorCode.ExistingAccount,
    message: 'The provided email address already exists for a different account and cannot be added to this one.',
  },
  {
    code: ErrorCode.GeneralException,
    message: DEFAULT_ERROR_MESSAGE,
  },
  {
    code: ErrorCode.InvalidInvitation,
    message: 'The link you have used is either invalid, references an already accepted invitation, or has expired.',
  },
  {
    code: ErrorCode.InvalidInvitationEmail,
    message: 'The email you provided does not match the email associated with this invitation.',
  },
  {
    code: ErrorCode.InvalidInputParameter,
    message: 'Invalid input parameter.',
  },
  {
    code: ErrorCode.NoRecordFound,
    message: 'No record found.',
  },
  {
    code: ErrorCode.TimeoutError,
    message: `Your request has timed out. Please contact ${SUPPORT_EMAIL}`,
  },
  {
    code: ErrorCode.UserIdentityNotFound,
    message: 'Sorry, that is not the correct password',
  },
  {
    code: ErrorCode.InvalidRecaptcha,
    message: 'Unable to invite user. Invalid recaptcha. Please try again later.',
  },
  {
    code: ErrorCode.InputParameterRequired,
    message: '__FIELD__ is required',
  },
]

export const getError = (key: string): Error =>
  allErrors.find((e: Error) => e.code.toString() === key)

export const getErrorMessage = (key: string): string =>
  (allErrors.find((e: Error) => e.code.toString() === key) || {}).message
