import { ajax } from 'rxjs/ajax'
import uuid from 'uuidv4'
import { getPublicServerUrl } from './constants'

import * as RasaCookies from 'generic/cookies'
import * as StoreConstants from 'store/constants'
import * as AuthConstants from 'auth/constants'

export const isLocal = (): boolean => {
  return window.location.href.startsWith('http://localhost:')
}

export const redirectTo = (host: string, queryParams: string[]) => {
  const community = window.localStorage.getItem(StoreConstants.SELECTED_COMMUNITY)
  const cookie: RasaCookies.RasaCookie = {
    maxAge: 30,
    name: `t${Date.now()}`,
    value: window.localStorage.getItem(AuthConstants.RASA_AUTH_TOKEN),
  }
  RasaCookies.setCookie(cookie)

  queryParams.push(`${RasaCookies.REDIRECT_COOKIE_PARAM}=${cookie.name}`)
  queryParams.push(`SELECTED_COMMUNITY=${community}`)

  window.location.href = `${host}?${queryParams.join('&')}`
}

export const generateGuid = () => uuid()

export const writeLog = (payload) => {
  if ( process.env.SKIP_LOG ) {
    return
  } else {
    try {
      const url: string = getPublicServerUrl() + '/monitoring/dashboard/log'
      const headers = {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'rxjs-custom-header': 'Rxjs',
      }
      return ajax({
        body: payload,
        headers,
        method: 'POST',
        url,
      }).toPromise()
    } catch (error) {
      // do nothing. No need to print. Error would in the network
    }
  }
}
